<template>
  <v-layout child-flex>
    <v-card>
      <v-card-title>
        Deploy Release
        &lt;-
        <v-btn class="ml-2" :to="{ name: 'tools-create-release' }">Create Release</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-select :items="Object.keys(deployOptions)" v-model="release.app" :disabled="loading.create"
              @input="appChange" label="Select an app" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select :items="deployOptions[release.app] || []" v-model="release.service"
              :disabled="!release.app || loading.deploy" @input="serviceChange"
              label="Select an service to release"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select :items="appEnvironments" v-model="release.env" :loading="loading.envs"
              :disabled="!release.app || loading.deploy" @input="envChange"
              label="Select an environment to release to"></v-select>
          </v-col>
        </v-row>
        <v-row v-if="strakerRequired">
          <v-col cols="12" md="6">
            <v-checkbox
                class="mt-0 mb-4"
                v-model="straker.checked"
                label="I have raised a Straker translation request"
                hide-details
            ></v-checkbox>
            <v-btn :href="straker.link" target="_blank">Check Straker status</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select :items="versions" v-model="release.version" :loading="loading.versions"
              :disabled="!release.app || loading.deploy" label="Select a version to deploy"></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-2" color="primary" @click="deployRelease()" :loading="loading.deploy"
          :disabled="loading.deploy || !release.app || !release.service || !release.env || !release.version || !strakerChecked">Deploy
          Release</v-btn>
      </v-card-actions>

      <v-card-text v-if="dbMigrationCommands">
        <v-row>
          <v-col cols="12" lg="6">
            <h2 class="mb-4">MIGRATE DATABASE POST RELEASE</h2>
            <div v-for="(command, idx) in dbMigrationCommands" :key="idx" class="font-weight-bold">
              {{ command }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="currentVersions.length">
        <v-row>
          <v-col cols="12" lg="6">
            Current Versions
            <v-icon class="float-right" @click="getCurrentVersions()">mdi-refresh</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6">
            <v-data-table :headers="versionHeaders" :items="currentVersions" class="elevation-1"
              :items-per-page="-1"></v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="output.length > 0">
        <v-row>
          <v-col cols="12">
            <h2>Deploy output</h2>
            <pre>{{ output }}</pre>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-layout>
</template>

<script>
import { http, apiUrl } from '@/services/http';

export default {
  name: 'ToolsDeployRelease',
  components: {},
  data: () => ({
    straker: {
      checked: false,
      link: "https://languagecloud.strakertranslations.com/login",
    },
    release: {
      app: null,
      service: null,
      env: null,
      version: null,
    },
    deployOptions: {
      coursepro: [
        { text: 'CoursePro 3 (All)', value: 'coursepro' },
        { text: 'CoursePro Only', value: 'coursepro-only' },
        { text: 'Home Portal', value: 'home-portal' },
        { text: 'Strive', value: 'strive' },
        { text: 'Strive API', value: 'strive-api' },
        { text: 'Strive Client', value: 'strive-client' },
      ],
      bg: [
        { text: 'BG (All)', value: 'bg' },
        { text: 'BG API', value: 'bg-api' },
        { text: 'BG Client', value: 'bg-client' },
      ],
      insight: [
        { text: 'Insight-UK', value: 'insight-uk' },
        { text: 'Insight-US', value: 'insight-us' },
      ],
      merlin: [
        { text: 'Merlin', value: 'merlin' },
      ],
    },
    environments: [
      { text: 'QA', value: 'qa' },
      { text: 'Dev', value: 'dev' },
      { text: 'Staging', value: 'staging' },
      { text: 'Production', value: 'prod' },
      { text: 'Feature', value: 'feature' },
    ],
    appEnvironments: [],
    versions: [],
    currentVersions: [],
    versionHeaders: [
      { text: 'Environment', value: 'service' },
      { text: 'Version', value: 'version' },
    ],
    output: [],
    loading: {
      envs: false,
      versions: false,
      deploy: false,
    },
    dbMigrationCommands: '',
  }),
  methods: {
    async appChange() {
      this.release.service = null;
      this.release.env = null;
      this.release.version = null;
      this.dbMigrationCommands = '';
      await this.getAppEnvironments();
    },
    async envChange() {
      this.release.version = null;
      this.loading.versions = true;
      await this.getVersions();
      if (this.release.service) {
        await this.getCurrentVersions();
        await this.getDbMigrationCommands();
      }
      this.loading.versions = false;
    },
    async serviceChange() {
      await this.getAppEnvironments();
      if (!this.appEnvironments.map((e) => e.value).includes(this.release.env)) {
        this.release.env = null;
      }
      if (this.release.env) {
        this.loading.versions = true;
        await this.getCurrentVersions();
        await this.getDbMigrationCommands();
        this.loading.versions = false;
      }
    },
    async getCurrentVersions() {
      const params = this.release;
      const response = await http.get(`${apiUrl}/environment/versions`, { params });
      this.currentVersions = response.data;
    },
    async getAppEnvironments() {
      this.loading.env = true;
      const params = { app: this.release.app, service: this.release.service };
      const response = await http.get(`${apiUrl}/release/app-environments`, { params });
      this.appEnvironments = this.environments.filter((e) => response.data.includes(e.value));
      this.loading.env = false;
    },
    async getVersions() {
      const params = { app: this.release.app, env: this.release.env };
      const response = await http.get(`${apiUrl}/release/versions`, { params });
      this.versions = response.data;
    },
    async getDbMigrationCommands() {
      const params = { service: this.release.service, env: this.release.env };
      const response = await http.get(`${apiUrl}/release/db-migration-commands`, { params });
      this.dbMigrationCommands = response.data;
    },
    async deployRelease() {
      try {
        await this.$dialog.confirm(
          `Are you sure you want to release ${this.release.version} to ${this.release.service} ${this.release.env}?`,
          {
            okText: 'CONFIRM',
            cancelText: 'Cancel',
            backdropClose: true,
            customClass: ''
          }
        );
      } catch (e) {
        // Cancel
        return;
      }

      try {
        this.output = [];
        this.loading.deploy = true;
        const response = await http.post(`${apiUrl}/release/deploy`, this.release);
        this.output = response.data.output;
        this.$toast.success(`Release ${this.release.version} deployed to ${this.release.env}`);
        await this.getCurrentVersions();
      } catch (e) {
        let err = '?';
        if (e.response && e.response.data) {
          err = e.response.data.error;
          this.output = e.response.data.output;
        }
        this.$toast.error(`Failed to deploy release - ${err}`);
      } finally {
        this.loading.deploy = false;
      }
    }
  },
  computed: {
    strakerRequired() {
      return (this.release.app === 'coursepro' && this.release.env === 'staging') ||
        (this.release.app === 'bg' && this.release.env === 'dev');
    },
    strakerChecked() {
      return !this.strakerRequired || this.straker.checked;
    },
  },
}

</script>

<style scoped></style>
